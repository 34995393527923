import * as React from 'react'
import { css, Global, withTheme } from '@emotion/react'

const HeaderStyles = withTheme(({ theme }) => (
  <Global
    styles={css`
      .leshen-header {
        font-size: 18px;
        line-height: 24px;
        padding-top: 21px !important;
        padding-bottom: 33px !important;
        background: linear-gradient(
            90deg,
            ${theme.colors.tertiary.base.hex} 0%,
            ${theme.colors.primary.base.hex} 100%
          )
          left bottom #ffffff no-repeat !important;
        background-size: 100% 12px !important;

        .primary-link {
          text-transform: uppercase;

          .subnav {
            top: 70px !important;
          }
        }
      }
    `}
  />
))

export default HeaderStyles
