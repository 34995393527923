import * as React from 'react'
import { css, Global, withTheme } from '@emotion/react'

const PriceStyles = withTheme(() => (
  <Global
    styles={css`
      .before {
        margin-bottom: 16px !important;
        font-size: 16px !important;
      }
    `}
  />
))

export default PriceStyles
