import React from 'react'
import { withTheme } from '@emotion/react'
import FooterStyles from './Footer.styles'
import HeaderStyles from './Header.styles'
import PackageCardStyles from './PackageCard.styles'
import PriceStyles from './Price.styles'

const GlobalStyles = withTheme(() => (
  <>
    <FooterStyles />
    <HeaderStyles />
    <PackageCardStyles />
    <PriceStyles />
  </>
))

export default GlobalStyles
