import * as React from 'react'
import { css, Global, withTheme } from '@emotion/react'

const PackageCardStyles = withTheme(() => (
  <Global
    styles={css`
      .leshen-package-card {
        .leshen-phone-cta {
          border-radius: 0 !important;
        }

        .main-content {
          height: 100%;
        }
      }
    `}
  />
))

export default PackageCardStyles
